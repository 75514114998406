body {
    font-family: 'Antonio', sans-serif;
    background-color: #111;
    color: var(--mid-grey);

}

h1, h2, h3, h4, h5 {
    padding: 0;
    margin: 0;
}

:root {
    --orange: #ed9174;
    --red: #d24a33;
    --blue: #529ac5;
    --aqua: #6ce8e9;
    --light-grey: #949bad;
    --mid-grey: #63687e;
    --dark-grey: #2a3040;
    --white: #cbced8;
}


@keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }
  
  .fade-in {
    animation: fadeIn .7s ease-in-out;
  }
  
