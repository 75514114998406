.app {
  min-width: 1024px;
  display: grid;
  grid-template-columns: repeat(20, 1fr);
  grid-row-gap: 10px;
}

.upper-container {
  grid-column: 4 / 20;
  grid-row: 1 / 3;
  background-color: #111;
  border-bottom-left-radius: 60px;
  margin-bottom: 20px;
  display: flex;
  
}

.upper-container-left {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.upper-container-right {
  width: 50%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(3, 1fr);
}

.lower-container {
  grid-column: 4 / 20;
  grid-row: 3 / 21;
  background-color: #111;
  border-top-left-radius: 60px;
  margin-top: 20px;
  display: flex;
}

